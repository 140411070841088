import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { getSystemHeaders } from '../libs/system'
import { SnomedCT } from '../model/snomedCT'
import { FilterParams, unversionedApiUrl } from './common'

const SNOMED_TAG = 'snomedct'
const SNOMED_FAV_TAG = 'snomedct-favourites'

type SnomedCtCode = string

type SnomedCtCodeTerm = { code: string; caseSignificanceId: string }

export const snomedctApi = createApi({
	reducerPath: 'snomedctApi',
	tagTypes: [SNOMED_TAG, SNOMED_FAV_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getSnomedByCode: builder.query<SnomedCT, SnomedCtCode>({
			query: code => ({
				url: `/system/v2/snomedct/find/code/${code}`,
			}),
			providesTags: [SNOMED_TAG],
		}),
		getMultipleSnomedByCode: builder.query<SnomedCT[], SnomedCtCodeTerm[]>({
			query: codes => ({
				url: `v1/snomedct/multiple`,
				method: 'POST',
				body: { codes },
			}),
			transformResponse: (res: (SnomedCT | null)[]): SnomedCT[] => {
				return res.filter(ct => ct !== null) as SnomedCT[]
			},
			providesTags: [SNOMED_TAG],
		}),
		getFilteredSnomedCt: builder.query<SnomedCT[], FilterParams>({
			query: ({
				filter,
				propNames = ['code', 'term'],
				caseSignificanceId,
				favourites,
			}: FilterParams) => {
				if (favourites === true) {
					return {
						url: '/system/v2/snomedct/favourites',
						headers: getSystemHeaders(),
					}
				}

				const parseNum = (str: string) => str.replace(/[^.\d]/g, '')

				const query = {
					q: filter,
					propNames: propNames,
					caseSignificanceId:
						caseSignificanceId && parseNum(caseSignificanceId),
				}

				const queryString = qs.stringify(query, { arrayFormat: 'comma' })

				const url = `/system/v2/snomedct/paginated?${queryString}`

				return {
					url,
				}
			},
			providesTags: (result, error, query) => [
				query.favourites ? SNOMED_FAV_TAG : SNOMED_TAG,
			],
		}),
		setSnomedFavourite: builder.mutation<
			SnomedCT,
			{ code: string; favourite: boolean }
		>({
			query: ({ code, favourite }) => ({
				url: `/v1/users/snomedct/${code}/favourite`,
				method: 'PUT',
				body: {
					favourite,
				},
			}),
			invalidatesTags: [SNOMED_TAG, SNOMED_FAV_TAG],
		}),
	}),
})

export const {
	useGetSnomedByCodeQuery,
	useGetFilteredSnomedCtQuery,
	useSetSnomedFavouriteMutation,
	useGetMultipleSnomedByCodeQuery,
} = snomedctApi
